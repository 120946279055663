import Vue from 'vue'
export default {
    namespaced: true,
    state: {
        setupProgress: null,
        setupProgressRequired: null,
        setupProgressFull: null,
        redirect: true,
        firstLoad : false,
        deletedConfig: null,
        returnablePackagingEnabled:false
    },
    getters: {
        setupProgress: state => {
            return state.setupProgress
        },
        deletedConfig: state => {
            return state.deletedConfig
        },
        redirect: state => {
            return state.redirect
        },
        firstLoad: state => {
            return state.firstLoad
        },
        returnablePackagingEnabled: state => state.returnablePackagingEnabled || localStorage.getItem('returnablePackagingEnabled') === 'true'
    },
    mutations: {
        SET_SETUP_PROGRESS(state, status) {
            state.setupProgress = status
        },
        SET_DELETED_CONFIG(state, status) {
            state.deletedConfig = status
        },
        SET_REDIRECT(state, data) {
            state.redirect = data
        },
        SET_RETURNABLE_PACKAGING_ENABLED(state, status) {
            state.returnablePackagingEnabled = status
        }
    },
    actions: {
        async getSetupProgress({commit}) {
            const response = await Vue.prototype.$http.get('/api/client/v1/installation_wizard/progress')
                const progress = response.data
                localStorage.setItem('progress', progress)
                commit('SET_SETUP_PROGRESS', progress)
        },
        async setDeletedConfig({commit}) {
            const response = await Vue.prototype.$http.get('/api/client/v1/invoice_config/deleted')
            const deletedConfig = response.data
            commit('SET_DELETED_CONFIG', deletedConfig.show)
        },
        setRedirect({commit}, data) {
            commit('SET_REDIRECT', data)
        },
       async setReturnablePackagingEnabled({commit}) {
            const res1 = await Vue.prototype.$http.get('/api/client/v1/client/data')
            const clientData = res1.data ?? {}
            localStorage.setItem('returnablePackagingEnabled', clientData.returnable_packaging_enabled)
            commit('SET_RETURNABLE_PACKAGING_ENABLED', clientData.returnable_packaging_enabled)
        }
    }

}
